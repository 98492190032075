export default {
  mounted() {
    // if (this.$mq === 'sm' || this.$mq === 'md' || this.$mq === 'lg') {
    setTimeout(() => {
      if (this.$route.query.mobile !== 'true') {
        this.createTawkTo();
      }
    }, 5000);
    // }
  },
  methods: {
    createTawkTo() {
      var Tawk_API = Tawk_API || {},
        // eslint-disable-next-line no-unused-vars
        Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement('script'),
          s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.id = 'tawktoScript';
        s1.src = 'https://embed.tawk.to/6752bccb2480f5b4f5a891db/1iedj2uqs';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
    },
  },
};
