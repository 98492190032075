import i18n from '@/plugins/i18n';

const requireContext = require.context('./search', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  projects: [],
  projectsMap: [],
  listings: [],
  agents: [],
  premierProjects: [],
  geoJson: [],
  meta: {},
  metaMap: {},
  metaAgent: {},
  hoverActive: false,
  shortlists: [],
  isShowMenuMobile: false,
  showMobileFilter: false,
  showMobileSort: false,
  city: {},
  selectedCategoryId: null,
  selectedProjectStatusId: null,
  openCategories: true,
  coverSearch: true,
  focusInputSearch: false,
  sortByOptions: [
    { id: 1, name: 'relevance' },
    { id: 2, name: 'last_update' },
    { id: 3, name: 'price_asc' },
    { id: 4, name: 'price_dsc' },
  ],
  sortByOptionsSecondary: [
    { id: 1, name: 'recommended', sort: 'date', order: 'desc' },
    { id: 3, name: 'price_asc', sort: 'price', order: 'asc' },
    { id: 4, name: 'price_dsc', sort: 'price', order: 'desc' },
  ],
  categories: [
    { id: null, name: 'all', text: null },
    { id: 7, name: 'residential' },
    { id: 8, name: 'commercial' },
  ],
  projectStatuses: [
    { id: 1, name: 'all' },
    { id: 2, name: 'done' },
    { id: 3, name: 'in_progress' },
  ],
  currentKeyword: null,
  searchProjectOptions: [],
  searchTerm: null,
  searchTermId: null,
  searchTermCategory: null,
  page: 1,
  rentPeriod: {
    id: null,
    name: null,
  },
  rentPrice: {
    id: null,
    name: null,
  },
  selectedListingType: {
    id: 2,
  },
  selectedPropertyType: null,
  selectedPropertyTypes: [],
  rentTypeId: [],
  buildingSizeMin: null,
  buildingSizeMax: null,
  sellPriceMin: null,
  sellPriceMax: null,
  tempSellPriceMin: null,
  tempSellPriceMax: null,
  rentPriceMin: null,
  rentPriceMax: null,
  sortBy: { id: 1, name: 'recommended', sort: 'date', order: 'desc' },
  category: { id: null, name: 'all' },
  projectStatus: { id: 1, name: 'all' },
  isLoading: false,
  isLoadingMap: false,
  seed: null,
  categoryNavList: [
    { name: 'active', isActive: true, count: 0 },
    { name: 'draft', isActive: false, count: 0 },
    { name: 'notActive', isActive: false, count: 0 },
    { name: 'sold', isActive: false, count: 0 },
  ],
  activeFilterSidebar: false,
  minBedroom: null,
  minBedrooms: [
    {
      id: null,
      name: 'Semua Kamar Tidur',
    },
    {
      id: 0,
      name: 'Tipe Studio',
    },
    {
      id: 1,
      name: '1 Kamar Tidur',
    },
    {
      id: 2,
      name: '2 Kamar Tidur',
    },
    {
      id: 3,
      name: '3 Kamar Tidur',
    },
    {
      id: 4,
      name: '4 Kamar Tidur',
    },
    {
      id: 5,
      name: '5+ Kamar Tidur',
    },
  ],
  totalUnit: null,
  subsidized: false,
  selectedRegions: [],
});

export const getters = {
  activeCategory(state) {
    return state.categoryNavList.find((category) => {
      return category.isActive;
    }).name;
  },
  activeSortBy(state) {
    return state.sortByOptions.find(function (sortBy) {
      return sortBy.id === state.sortBy.id;
    });
  },
  listingType(state, getters, rootState) {
    return state.selectedListingType.id === 1
      ? rootState.v2.masters.listingTypeEnum.PRIMARY
      : rootState.v2.masters.listingTypeEnum.SECONDARY;
  },
  sortBys(state) {
    if (state.selectedListingType && state.selectedListingType.id === 2) {
      return state.sortByOptionsSecondary;
    } else {
      return state.sortByOptions;
    }
  },
  isSecondary(state) {
    return state.selectedListingType === null || state.selectedListingType.id === 2;
  },
  seoText(state, getters) {
    let text = '';
    if (state.selectedPropertyType && state.selectedPropertyType.name !== null) {
      if (state.selectedPropertyType.name.toLowerCase() === 'semua tipe properti') {
        text += getters.isSecondary ? i18n.t('general.property') : i18n.t('general.primaryListing');
      } else {
        text += state.selectedPropertyType.name;
        text += getters.isSecondary ? '' : ' Baru';
      }
    } else {
      if (
        state.selectedPropertyTypes &&
        JSON.stringify(state.selectedPropertyTypes) !== '[]' &&
        state.selectedPropertyTypes.length > 0
      ) {
        text += state.selectedPropertyTypes[0].name;
        text += getters.isSecondary ? '' : ' Baru';
      } else {
        text += getters.isSecondary ? i18n.t('general.property') : i18n.t('general.primaryListing');
      }
    }
    text += ' ' + i18n.t('general.availableS');

    let location = null;
    if (state.searchTerm) {
      location = state.searchTerm;
    } else if (state.selectedRegions && state.selectedRegions.length > 0) {
      location = state.selectedRegions[0].name;
    }
    if (location) {
      text +=
        ' di ' +
        location.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
    }
    return text;
  },
  seoTextExtra(state, getters) {
    let text = getters.seoText;
    if (!text.includes(' di ')) {
      text += ' di Jakarta, Bogor, Depok, Tangerang, Bekasi dan Seluruh Indonesia';
    }
    return text;
  },
  seoTitle(state, getters) {
    let text = getters.seoTextExtra;
    text += ' - Harga Terbaru ' + new Date().getFullYear();
    // if (text.length > 50) text = text.substring(0, 47) + '...';
    text += ' | BeliRumah';
    return text;
  },
  seoDesc(state, getters) {
    let text = getters.seoTextExtra;
    text = 'BeliRumah.co - Cari ' + text + '. Tersedia ' + state.totalUnit + ' ' + text + '.';
    if (text.length > 300) text = text.substring(0, 300) + '...';
    text += ' | BeliRumah';
    return text;
  },
};

export const mutations = {
  SET_SHOW_MOBILE_FILTER(state, payload) {
    state.showMobileFilter = payload;
  },
  SET_SHOW_MOBILE_SORT(state, payload) {
    state.showMobileSort = payload;
  },
  SET_SELECTED_CATEGORY_ID(state, payload) {
    state.selectedCategoryId = payload;
  },
  SET_SELECTED_PROJECT_STATUS_ID(state, payload) {
    state.selectedProjectStatusId = payload;
  },
  SET_PROJECTS_MAP(state, payload) {
    state.projectsMap = payload;
  },
  SET_META_MAP(state, payload) {
    state.metaMap = payload;
  },
  set_listings(state, payload) {
    state.listings = payload.data;
    state.meta = payload.meta;
  },
  set_projects(state, payload) {
    state.projects = payload.data;
    state.meta = payload.meta;
  },
  set_geo_json(state, payload) {
    state.geoJson = payload;
  },
  set_hover_map(state, payload) {
    state.hoverActive = payload;
  },
  set_shortlists(state, payload) {
    state.shortlists = payload;
  },
  SET_SHOW_MENU_MOBILE(state, payload) {
    state.isShowMenuMobile = payload;
  },
  set_city(state, payload) {
    state.city = payload;
  },
  set_premier_projects(state, payload) {
    state.premierProjects = payload.data;
  },
  SET_OPEN_CATEGORIES(state, payload) {
    state.openCategories = payload;
  },
  SET_COVER_SEARCH(state, payload) {
    state.coverSearch = payload;
  },
  SET_FOCUS_INPUT_SEARCH(state, payload) {
    state.focusInputSearch = payload;
  },

  SET_SEARCH_TERM_ID(state, payload) {
    state.searchTermId = payload;
  },
  SET_SEARCH_TERM_CATEGORY(state, payload) {
    state.searchTermCategory = payload;
  },
  SET_SEARCH_TERM(state, payload) {
    state.searchTerm = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_RENT_PERIOD(state, payload) {
    state.rentPeriod = payload;
  },
  SET_RENT_PRICE(state, payload) {
    state.rentPrice = payload;
  },
  SET_SELECTED_LISTING_TYPE(state, payload) {
    state.selectedListingType = payload;
  },
  SET_SELECTED_PROPERTY_TYPE(state, payload) {
    state.selectedPropertyType = payload;
  },
  SET_SELECTED_PROPERTY_TYPES(state, payload) {
    state.selectedPropertyTypes = payload;
  },
  SET_RENT_TYPE_ID(state, payload) {
    state.rentTypeId = payload;
  },
  SET_BUILDING_SIZE_MIN(state, payload) {
    state.buildingSizeMin = payload;
  },
  SET_BUILDING_SIZE_MAX(state, payload) {
    state.buildingSizeMax = payload;
  },
  SET_SELL_PRICE_MIN(state, payload) {
    state.sellPriceMin = payload;
  },
  SET_SELL_PRICE_MAX(state, payload) {
    state.sellPriceMax = payload;
  },
  SET_TEMP_SELL_PRICE_MIN(state, payload) {
    state.tempSellPriceMin = payload;
  },
  SET_TEMP_SELL_PRICE_MAX(state, payload) {
    state.tempSellPriceMax = payload;
  },
  SET_RENT_PRICE_MIN(state, payload) {
    state.rentPriceMin = payload;
  },
  SET_RENT_PRICE_MAX(state, payload) {
    state.rentPriceMax = payload;
  },
  SET_SORT_BY(state, payload) {
    state.sortBy = payload;
  },
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },
  SET_PROJECT_STATUS(state, payload) {
    state.projectStatus = payload;
  },
  SET_SEED(state, payload) {
    state.seed = payload;
  },
  SET_CURRENT_KEYWORD(state, payload) {
    state.currentKeyword = payload;
  },
  SET_ACTIVE_FILTER_SIDEBAR(state, payload) {
    state.activeFilterSidebar = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_MAP(state, payload) {
    state.isLoadingMap = payload;
  },
  SET_AGENTS(state, payload) {
    state.agents = payload;
  },
  SET_META_AGENT(state, payload) {
    state.metaAgent = payload;
  },
  SET_MIN_BEDROOM(state, payload) {
    state.minBedroom = payload;
  },
  SET_TOTAL_UNIT(state, payload) {
    state.totalUnit = payload;
  },
  SET_SUBSIDIZED(state, payload) {
    state.subsidized = payload;
  },
  SET_SELECTED_REGIONS(state, payload) {
    state.selectedRegions = payload;
  },
  CLEAR_FILTER(state) {
    state.page = 1;
    state.seed = null;
    state.selectedCategoryId = null;
    state.rentPeriod = {
      id: null,
      name: null,
    };
    state.rentPrice = {
      id: null,
      name: null,
    };
    // state.selectedListingType = { id: 2 };
    state.selectedPropertyType = null;
    state.selectedPropertyTypes = [];
    state.rentTypeId = [];
    state.buildingSizeMin = null;
    state.buildingSizeMax = null;
    state.sellPriceMin = null;
    state.sellPriceMax = null;
    state.tempSellPriceMin = null;
    state.tempSellPriceMax = null;
    state.rentPriceMin = null;
    state.rentPriceMax = null;
    state.sortBy = { id: 1, name: 'recommended', sort: 'date', order: 'desc' };
    state.category = { id: null, name: 'all' };
    state.minBedroom = null;
    state.subsidized = false;
  },
};

export const actions = {
  convertCategoryIdToUrlName(_, categoryId) {
    switch (categoryId) {
      case 6:
        return 'stay';
      case 1:
        return 'business';
      case 2:
        return 'inventory';
      default:
        return null;
    }
  },

  convertCategoryStringToId(_, stringData) {
    if (!stringData) return null;
    switch (stringData.toUpperCase()) {
      case 'STAY':
        return 6;
      case 'BUSINESS':
        return 1;
      case 'INVENTORY':
        return 2;
      default:
        return null;
    }
  },

  async getSuggestions(_, { query }) {
    let response = await this.$http.get('/api/v2/search_listing/get_region_suggestions', {
      params: {
        query: query,
      },
    });

    return response.data;
  },

  async getProjectMap({ commit, dispatch }, { query, params }) {
    let self = this;

    if (query) await dispatch('loadRouteQuery', { query: query, params: params });

    query = Object.assign({}, query, {
      page: 1,
      paginate: 100,
      user_uuid: params.userUuid,
    });
    console.log('search/getProjectMap Query: ', query);

    let querySearch = JSON.parse(JSON.stringify(query));
    return new Promise(async function (resolve, reject) {
      try {
        commit('SET_LOADING_MAP', true);
        let response = await self.$http.get('/api/search', {
          params: querySearch,
        });
        console.log('search/getProjectMap Result: ', response.data.data);
        commit('SET_PROJECTS_MAP', response.data.data.data);
        commit('SET_META_MAP', response.data.data.meta);
        resolve(response);
      } catch (e) {
        reject(e);
      } finally {
        commit('SET_LOADING_MAP', false);
      }
    });
  },
  async getDiscountProject({ commit, state }) {
    let self = this;
    let query = {
      page: state.page,
      discount: true,
    };

    console.log('search/getDiscountProject Query: ', query);

    let allPromises = [];
    let querySearch = JSON.parse(JSON.stringify(query));
    allPromises.push(
      new Promise(async function (resolve, reject) {
        try {
          querySearch = Object.assign({}, querySearch, {
            paginate: 30,
            seed: state.seed,
          });
          let response = await self.$http.get('/api/search', {
            params: querySearch,
          });
          console.log('search/getDiscountProject Result: ', response.data.data);
          commit('set_projects', {
            data: response.data.data.data,
            meta: response.data.data.meta,
          });
          resolve(response);
        } catch (e) {
          reject(e);
        }
      }),
    );

    await Promise.all(allPromises);
  },
  async getSearchProject({ commit, state, dispatch, rootState }, { query, params }) {
    let self = this;
    let allPromises = [];

    if (query) await dispatch('loadRouteQuery', { query: query, params: params });

    query = Object.assign({}, query, {
      page: state.page,
      q: state.searchTerm,
    });
    // if (state.selectedPropertyType && state.selectedPropertyType.id) {
    //   query.property_type_id = state.selectedPropertyType.id;
    // } else {
    //   delete query.property_type_id;
    // }
    if (state.selectedPropertyTypes && state.selectedPropertyTypes.length > 0) {
      query.property_type_id = state.selectedPropertyTypes.map((value) => value.id);
    } else {
      delete query.property_type_id;
    }
    if (state.selectedRegions && state.selectedRegions.length > 0) {
      const regionIds = state.selectedRegions.map((value) => value.id);
      query.region_ids = regionIds;
      delete query.regions;

      allPromises.push(dispatch('featuredAgent/getList', { regionIds: regionIds }));
    } else {
      delete query.region_ids;
      delete query.regions;

      commit('featuredAgent/RESTORE_DEFAULT_VALUE');
    }
    if (state.minBedroom) query.bedroom = state.minBedroom.id;

    if (!state.seed) {
      dispatch('generateSearchSeed');
    }

    if (params.userUuid) {
      query = Object.assign({}, query, {
        user_uuid: params.userUuid,
      });
    }
    if (params.agentUuid) {
      query = Object.assign({}, query, {
        user_uuid: params.agentUuid,
      });
    }

    if (
      params.propertyType &&
      params.propertyType.toLowerCase().replace(/ /g, '-').includes('subsidi')
    ) {
      query.subsidized = true;
      // query.property_type_id = state.selectedPropertyType.originalId;
    } else {
      delete query.subsidized;
    }

    console.log('search/getSearchProject Query: ', query);

    let querySearch = JSON.parse(JSON.stringify(query));
    querySearch = Object.assign({}, querySearch, {
      paginate: 30,
      seed: state.seed,
    });

    allPromises.push(
      new Promise(async function (resolve, reject) {
        try {
          if (state.selectedListingType.id === 2) {
            if (state.sortBy) {
              querySearch.sort = state.sortBy.sort;
              querySearch.order = state.sortBy.order;
            }
            let response = await self.$http.get('/api/v2/search_listing', {
              params: querySearch,
            });
            commit('set_listings', {
              data: response.data.data.data,
              meta: response.data.data.meta,
            });
            commit('set_projects', {
              data: [],
              meta: response.data.data.meta,
            });
            commit('SET_TOTAL_UNIT', response.data.data.meta.total);
            resolve(response);
          } else {
            let response = await self.$http.get('/api/search', {
              params: querySearch,
            });
            commit('set_listings', {
              data: [],
              meta: response.data.data.meta,
            });
            if (rootState.v2.profile.owner) {
              response.data.data.data.forEach(function (part, index, theArray) {
                let temp = `${theArray[index].links.detail}?utm_source=agent_app&utm_medium=listing_catalog&agent_uuid=${rootState.v2.profile.owner.uuid}`;
                theArray[index].links.detail = temp;
              });
            }

            commit('set_projects', {
              data: response.data.data.data,
              meta: response.data.data.meta,
            });
            resolve(response);
          }
        } catch (e) {
          reject(e);
        }
      }),
    );

    allPromises.push(
      new Promise(async function (resolve, reject) {
        try {
          if (state.selectedListingType.id !== 2) {
            let response = await self.$http.get('/api/search/get_total_unit', {
              params: querySearch,
            });
            commit('SET_TOTAL_UNIT', response.data.data);
            resolve(response);
          } else {
            resolve(null);
          }
        } catch (e) {
          reject(e);
        }
      }),
    );

    // if (this.page === 1) {
    //   await commit('set_geo_json', []);
    //   commit('getGeoJsonProjects', query);
    // }

    await Promise.all(allPromises);
  },
  async getGeoJsonProjects({ state, commit }, query) {
    query = Object.assign({}, query, {
      page: state.page,
    });

    let response = await this.$http.get('/api/search/geo-json', {
      params: query,
    });

    commit('set_geo_json', response.data.data);
  },
  async getShortlistUuids({ commit }, { listingType }) {
    await this.$http.get('/api/shortlist/get_uuids?type=' + listingType).then((response) => {
      commit('set_shortlists', response.data.data);
    });
  },
  async getSimilar(_, { projectUuid, listingUuid, subsidized, mq }) {
    console.log('mqnyaaaaa aa', mq);
    let params = {
      paginate: mq === 'xs' ? 4 : 3,
    };
    if (projectUuid) {
      params.project_uuid = projectUuid;
    } else if (listingUuid) {
      params.listing_uuid = listingUuid;
    }
    if (subsidized) {
      params.subsidized = subsidized;
    }
    let response = await this.$http.get(listingUuid ? '/api/v2/search_listing' : '/api/search', {
      params: params,
    });
    return response.data.data.data;
  },
  async getCityById(_, cityId) {
    let response = await this.$http.get('/api/search/city/' + cityId);

    return response.data;
  },
  async getCountProject() {
    let response = await this.$http.get('/api/search/count-project');
    return response.data;
  },
  async loadRouteQuery({ state, rootState, dispatch, commit, getters }, { query, params }) {
    console.log('search/loadRouteQuery Query: ', query);
    console.log('search/loadRouteQuery Params: ', params);

    await dispatch('loadSearchTermFromRoute', { query: query, params: params });

    if (query.category_filter_id) {
      commit('SET_SEARCH_TERM_ID', query.category_filter_id);
    } else {
      commit('SET_SEARCH_TERM_ID', null);
    }

    if (query.category) {
      commit('SET_SEARCH_TERM_CATEGORY', query.category);
    } else {
      commit('SET_SEARCH_TERM_CATEGORY', null);
    }

    if (query.page) {
      commit('SET_PAGE', query.page);
    } else {
      commit('SET_PAGE', 1);
    }

    if (query.rent_period) {
      let rentPeriod = rootState.v2.masters.rentPeriods.find(function (item) {
        return item.id === parseInt(query.rent_period);
      });

      if (rentPeriod) commit('SET_RENT_PERIOD', rentPeriod);
    } else {
      commit('SET_RENT_PERIOD', {
        id: null,
        name: null,
      });
    }

    if (query.rent_price) {
      commit('SET_RENT_PRICE', query.rent_price);
    } else {
      commit('SET_RENT_PRICE', {
        id: null,
        name: null,
      });
    }

    await dispatch('loadPropertyTypeFromRoute', { query: query, params: params });
    await dispatch('loadPropertyTypesFromRoute', { query: query, params: params });

    await dispatch('loadRegionsFromRoute', { query: query });

    if (
      params.propertyType &&
      params.propertyType.toLowerCase().replace(/ /g, '-').includes('subsidi')
    ) {
      commit('SET_SUBSIDIZED', true);
    } else {
      commit('SET_SUBSIDIZED', false);
    }

    if (query.rent_type_id && Array.isArray(query.rent_type_id)) {
      commit('SET_RENT_TYPE_ID', query.rent_type_id);
    } else {
      let rentTypeId = [];
      if (query.rent_type_id) {
        await dispatch('v2/masters/getRentTypeById', query.rent_type_id, { root: true });
        rentTypeId.push(query.rent_type_id);
      }
      commit('SET_RENT_TYPE_ID', rentTypeId);
    }

    if (query.building_size_min) {
      commit('SET_BUILDING_SIZE_MIN', query.building_size_min);
    } else {
      commit('SET_BUILDING_SIZE_MIN', null);
    }

    if (query.building_size_max) {
      commit('SET_BUILDING_SIZE_MAX', query.building_size_max);
    } else {
      commit('SET_BUILDING_SIZE_MAX', null);
    }

    if (query.min_price) {
      commit('SET_SELL_PRICE_MIN', 'Rp ' + query.min_price);
      commit('SET_TEMP_SELL_PRICE_MIN', 'Rp ' + query.min_price);
    } else {
      commit('SET_SELL_PRICE_MIN', null);
    }

    if (query.max_price) {
      commit('SET_SELL_PRICE_MAX', 'Rp ' + query.max_price);
      commit('SET_TEMP_SELL_PRICE_MAX', 'Rp ' + query.max_price);
    } else {
      commit('SET_SELL_PRICE_MAX', null);
    }

    if (query.rent_price_min) {
      commit('SET_RENT_PRICE_MIN', 'Rp ' + query.rent_price_min);
    } else {
      commit('SET_RENT_PRICE_MIN', null);
    }

    if (query.rent_price_max) {
      commit('SET_RENT_PRICE_MAX', 'Rp ' + query.rent_price_max);
    } else {
      commit('SET_RENT_PRICE_MAX', null);
    }

    if (query.listing_type_id) {
      let listingType = rootState.v2.masters.listingTypes.find(function (item) {
        return item.id === parseInt(query.listing_type_id);
      });

      commit('SET_SELECTED_LISTING_TYPE', listingType);
    } else {
      commit('SET_SELECTED_LISTING_TYPE', { id: 2 });
    }

    if (query.property_category) {
      commit('SET_SELECTED_CATEGORY_ID', parseInt(query.property_category));

      let category = state.categories.find(function (item) {
        return item.id === state.selectedCategoryId;
      });

      if (category) commit('SET_CATEGORY', category);
    } else {
      commit('SET_SELECTED_CATEGORY_ID', null);
      commit('SET_CATEGORY', { id: null, name: 'all' });
    }

    if (query.done_estimation) {
      commit('SET_SELECTED_PROJECT_STATUS_ID', parseInt(query.done_estimation));

      let projectStatus = state.projectStatuses.find(function (item) {
        return item.id === state.selectedProjectStatusId;
      });

      if (projectStatus) commit('SET_PROJECT_STATUS', projectStatus);
    } else {
      commit('SET_SELECTED_PROJECT_STATUS_ID', null);
      commit('SET_PROJECT_STATUS', { id: null, name: 'all' });
    }

    if (query.bedroom) {
      let id = parseInt(query.bedroom);
      let minBedroom = state.minBedrooms.find(function (item) {
        return item.id === id;
      });
      commit('SET_MIN_BEDROOM', minBedroom);
    } else {
      commit('SET_MIN_BEDROOM', null);
    }

    if (query.sort_by) {
      let sortBy = getters.sortBys.find(function (item) {
        return item.id === parseInt(query.sort_by);
      });

      commit('SET_SORT_BY', sortBy);
    } else {
      commit('SET_SORT_BY', getters.sortBys[0]);
    }
  },
  async loadSearchTermFromRoute({ commit }, { query, params }) {
    if (params.query) {
      commit(
        'SET_SEARCH_TERM',
        params.query
          .split('-')
          .join(' ')
          .replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()),
      );
    } else if (query.q) {
      commit('SET_SEARCH_TERM', query.q);
    } else {
      commit('SET_SEARCH_TERM', null);
    }
  },
  async loadPropertyTypeFromRoute({ commit, rootState }, { params }) {
    let propertyTypeName = null;
    if (params && params.propertyType) {
      propertyTypeName = params.propertyType.toLowerCase().replace(/-/g, ' ');
      let propertyType = null;
      if (propertyTypeName && propertyTypeName !== 'semua-tipe-properti') {
        propertyType = await rootState.v2.masters.propertyTypes.find(function (item) {
          return item ? item.name.toLowerCase() === propertyTypeName : false;
        });
        if (propertyType) propertyType = JSON.parse(JSON.stringify(propertyType));
      }
      commit('SET_SELECTED_PROPERTY_TYPE', propertyType ? propertyType : null);
      // }
      // else if (query.property_type_id) {
      // let propertyType = await rootState.v2.masters.propertyTypes.find(function(item) {
      //   return item ? item.id === parseInt(query.property_type_id) : false;
      // });
      // if (propertyType) propertyType = JSON.parse(JSON.stringify(propertyType));
      // commit('SET_SELECTED_PROPERTY_TYPE', propertyType ? propertyType : null);
    } else {
      commit('SET_SELECTED_PROPERTY_TYPE', null);
    }
  },
  async loadPropertyTypesFromRoute({ commit, dispatch, rootState }, { query, params }) {
    await dispatch('v2/masters/getPropertyTypes', query.property_type_id, {
      root: true,
    });
    if (query.property_type_id && Array.isArray(query.property_type_id)) {
      commit(
        'SET_SELECTED_PROPERTY_TYPES',
        query.property_type_id.map((id) => {
          return rootState.v2.masters.propertyTypes.find(function (item) {
            return item ? item.id === parseInt(id) : false;
          });
        }),
      );
    } else {
      let propertyTypes = [];
      if (query.property_type_id) {
        let propertyType = await dispatch(
          'v2/masters/getPropertyTypeById',
          query.property_type_id,
          {
            root: true,
          },
        );
        propertyTypes.push(propertyType);
      } else if (params && params.propertyType) {
        let propertyTypeName = params.propertyType.toLowerCase().replace(/-/g, ' ');
        if (propertyTypeName && propertyTypeName !== 'semua tipe properti') {
          let propertyType = await rootState.v2.masters.propertyTypes.find(function (item) {
            return item ? item.name.toLowerCase() === propertyTypeName : false;
          });
          if (propertyType) propertyType = JSON.parse(JSON.stringify(propertyType));
          propertyTypes.push(propertyType);
        }
      }
      commit('SET_SELECTED_PROPERTY_TYPES', propertyTypes);
    }
  },
  async loadRegionsFromRoute({ commit }, { query }) {
    if (query.regions && Array.isArray(query.regions)) {
      commit(
        'SET_SELECTED_REGIONS',
        query.regions.map((value) => {
          let splitValue = value.split('_');
          return {
            id: parseInt(splitValue[0]),
            name: splitValue[1],
          };
        }),
      );
    } else {
      let regions = [];
      if (query.regions) {
        let splitValue = query.regions.split('_');
        regions.push({
          id: parseInt(splitValue[0]),
          name: splitValue[1],
        });
      }
      console.log('SETTING REGIONS: ', regions);
      commit('SET_SELECTED_REGIONS', regions);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async goToSearchPage({ state, rootState }, { router, query, params }) {
    try {
      // Kalau parameter query tidak dimasukkan, maka fungsi ini akan load isi query dari state
      if (!query) {
        query = {};

        if (state.page) query.page = state.page;
        if (state.buildingSizeMin) query.building_size_min = state.buildingSizeMin;
        if (state.buildingSizeMax) query.building_size_max = state.buildingSizeMax;
        if (state.selectedCategoryId) query.property_category = state.selectedCategoryId;
        if (state.sortBy && state.sortBy.id) query.sort_by = state.sortBy.id;
        if (state.sellPriceMin) {
          let sellPriceMin = state.sellPriceMin.replace('Rp', '').trim();
          if (sellPriceMin) query.min_price = sellPriceMin;
        }
        if (state.sellPriceMax) {
          let sellPriceMax = state.sellPriceMax.replace('Rp', '').trim();
          if (sellPriceMax) query.max_price = sellPriceMax;
        }
        if (state.minBedroom) query.bedroom = state.minBedroom.id;
        if (state.selectedListingType) query.listing_type_id = state.selectedListingType.id;
        if (
          state.selectedPropertyTypes &&
          JSON.stringify(state.selectedPropertyTypes) !== '[]' &&
          state.selectedPropertyTypes.length > 0
        ) {
          query.property_type_id = state.selectedPropertyTypes.map((value) => value.id);
        } else {
          delete query.property_type_id;
        }
        console.log('CURRENT SELECTED REGIONS: ', state.selectedRegions);
        if (
          state.selectedRegions &&
          JSON.stringify(state.selectedRegions) !== '[]' &&
          state.selectedRegions.length > 0
        ) {
          query.regions = state.selectedRegions.map((value) => {
            let name = value.name;
            if (value.parents && value.parents.length > 1) {
              for (let i = 0; i < value.parents.length - 1; i++) {
                name += `, ${value.parents[i]}`;
              }
            }
            return `${value.id}_${name}`;
          });
        } else {
          delete query.regions;
        }

        // Khusus halaman search user project
        if (params.userHashId) {
          // if (state.selectedPropertyType) {
          //   query.property_type_id = state.selectedPropertyType.id;
          // }
        }
      }

      let path = '';

      // Halaman search user project
      if (params.userHashId) {
        path = `/user/${params.userName}/${params.userHashId}`;
      } else if (params.agentHashId) {
        path = `/agen/${params.agentName}/${params.agentHashId}`;
      }
      // Halaman search biasa
      else {
        path = '/cari/';

        if (state.selectedPropertyTypes && state.selectedPropertyTypes.length > 0) {
          let propertyTypeName = state.selectedPropertyTypes[0].name
            .toLowerCase()
            .replace(/ /g, '-');
          path += propertyTypeName + '/';
        } else {
          path += 'semua-tipe-properti/';
        }
        // delete query.property_type_id;
      }

      let useSearchTerm = false;
      if (state.searchTerm) {
        useSearchTerm = true;
      }
      if (useSearchTerm) {
        if (state.searchTermCategory) query.category = state.searchTermCategory;
        if (state.searchTermId) query.category_filter_id = state.searchTermId;

        // Halaman search biasa
        if (!params.userUuid && !params.agentUuid && !params.listingPartnerUrl) {
          path += encodeURI(state.searchTerm.split(' ').join('-').replace(/\//g, '')) + '/';
        }
        // Halaman search user listing dan search listing partner
        else {
          query.q = state.searchTerm;
        }
      } else {
        delete query.category;
        delete query.category_filter_id;
      }

      console.log('CALL goToSearchPage in search.js PATH: ', path);
      console.log('CALL goToSearchPage in search.js QUERY: ', query);
      await router
        .push({
          path: path,
          query: query,
        })
        .catch((e) => {
          throw e;
        });
    } catch (e) {
      console.log('ERROR goToSearchPage in search.js: ', e);
      throw e;
    }
  },
  setSelectedCategoryId({ commit }, { payload, query }) {
    if (query && query.property_category !== payload) {
      commit('SET_SELECTED_CATEGORY_ID', payload);
      commit('SET_PAGE', 1);

      try {
        query = Object.assign({}, query, { property_category: payload });
        delete query.rent_period;
        delete query.rent_type_id;
        delete query.property_type_id;
        return query;
      } catch (e) {
        console.log('ERROR setSelectedCategoryId in search.js: ', e);
      }
    }

    return null;
  },
  setSelectedProjectStatusId({ commit }, { payload, query }) {
    if (query && query.done_estimation !== payload) {
      commit('SET_SELECTED_PROJECT_STATUS_ID', payload);

      try {
        query = Object.assign({}, query, { done_estimation: payload });
        return query;
      } catch (e) {
        console.log('ERROR setSelectedProjectStatusId in search.js: ', e);
      }
    }

    return null;
  },
  setRentPeriod({ commit }, { payload, query }) {
    let rentPeriodId = payload ? payload.id : null;
    if (query && query.rent_period !== rentPeriodId) {
      commit('SET_RENT_PERIOD', payload);

      try {
        query = Object.assign({}, query, {
          rent_period: rentPeriodId,
        });
        return query;
      } catch (e) {
        console.log('ERROR setRentPeriod in search.js: ', e);
      }
    }

    return null;
  },
  setRentPrice({ commit }, { payload, query }) {
    state.rentPrice = payload;

    if (query && query.rent_price !== payload) {
      commit('SET_RENT_PRICE', payload);

      try {
        query = Object.assign({}, query, { rent_price: state.rentPrice });
        return query;
      } catch (e) {
        console.log('ERROR setRentPrice in search.js: ', e);
      }
    }

    return null;
  },
  setRentTypeId({ commit }, { payload, query }) {
    if (query) {
      let rent_type_id = query.rent_type_id;
      if (!rent_type_id) {
        rent_type_id = [];
      }

      if (JSON.stringify(rent_type_id) !== JSON.stringify(payload)) {
        commit('SET_RENT_TYPE_ID', payload);

        try {
          query = Object.assign({}, query, { rent_type_id: payload });
          return query;
        } catch (e) {
          console.log('ERROR setRentTypeId in search.js: ', e);
        }
      }
    }
    return null;
  },
  async setListingType({ commit, dispatch, getters }, { payload, query }) {
    if (query) {
      let listing_type_id = query.listing_type_id;
      if (!listing_type_id) {
        listing_type_id = 2;
      }

      if (listing_type_id && JSON.stringify(listing_type_id) !== payload.id) {
        commit('SET_SELECTED_LISTING_TYPE', payload);
        commit('SET_PAGE', 1);
        commit('SET_SORT_BY', getters.sortBys[0]);
        query = await dispatch('setPropertyType', {
          payload: { id: null, name: 'Semua Tipe Properti' },
          query: query,
        });
        commit('SET_SELECTED_PROPERTY_TYPES', []);
        delete query.property_type_id;

        try {
          query = Object.assign({}, query, { listing_type_id: payload.id });
          return query;
        } catch (e) {
          console.log('ERROR setListingType in search.js: ', e);
        }
      }
    }

    return null;
  },
  async setPropertyType({ commit }, { payload, query }) {
    if (query) {
      let property_type_id = query.property_type_id;
      if (!property_type_id) {
        property_type_id = {};
      }

      if (property_type_id && JSON.stringify(property_type_id) !== payload.id) {
        commit('SET_SELECTED_PROPERTY_TYPE', payload);
        commit('SET_PAGE', 1);

        try {
          query = Object.assign({}, query, { property_type_id: payload.id });
          return query;
        } catch (e) {
          console.log('ERROR setPropertyType in search.js: ', e);
        }
      }
    }

    return null;
  },
  async setPropertyTypes({ commit }, { payload, query }) {
    if (query) {
      let property_type_id = query.property_type_id;
      if (!property_type_id) {
        property_type_id = [];
      }

      if (property_type_id && JSON.stringify(property_type_id) !== payload.id) {
        commit('SET_SELECTED_PROPERTY_TYPES', payload);
        commit('SET_PAGE', 1);

        try {
          query = Object.assign({}, query, { property_type_id: payload.id });
          return query;
        } catch (e) {
          console.log('ERROR setPropertyTypes in search.js: ', e);
        }
      }
    }

    return null;
  },
  onBuildingSizeChange({ state }, { query }) {
    let buildingSizeMin = state.buildingSizeMin;
    let buildingSizeMax = state.buildingSizeMax;

    try {
      if (buildingSizeMin)
        query = Object.assign({}, query, {
          building_size_min: buildingSizeMin,
        });
      else delete query.building_size_min;
      if (buildingSizeMax)
        query = Object.assign({}, query, {
          building_size_max: buildingSizeMax,
        });
      else delete query.building_size_max;
      return query;
    } catch (e) {
      console.log('ERROR onBuildingSizeChange in search.js: ', e);
    }

    return null;
  },
  onSellPriceChange({ state }, { query }) {
    let sellPriceMin = state.sellPriceMin ? state.sellPriceMin.replace('Rp', '').trim() : null;
    let sellPriceMax = state.sellPriceMax ? state.sellPriceMax.replace('Rp', '').trim() : null;

    try {
      if (sellPriceMin)
        query = Object.assign({}, query, {
          min_price: sellPriceMin,
        });
      else delete query.min_price;
      if (sellPriceMax)
        query = Object.assign({}, query, {
          max_price: sellPriceMax,
        });
      else delete query.max_price;
      return query;
    } catch (e) {
      console.log('ERROR onSellPriceChange in search.js: ', e);
    }

    return null;
  },
  onRentPriceChange({ state }, { query }) {
    let rentPriceMin = state.rentPriceMin ? state.rentPriceMin.replace('Rp', '').trim() : null;
    let rentPriceMax = state.rentPriceMax ? state.rentPriceMax.replace('Rp', '').trim() : null;

    try {
      if (rentPriceMin)
        query = Object.assign({}, query, {
          rent_price_min: rentPriceMin,
        });
      else delete query.rent_price_min;
      if (rentPriceMax)
        query = Object.assign({}, query, {
          rent_price_max: rentPriceMax,
        });
      else delete query.rent_price_max;
      if (rentPriceMin || rentPriceMax) {
        query = Object.assign({}, query, {
          rent_period: 2,
        });
      }
      return query;
    } catch (e) {
      console.log('ERROR onRentPriceChange in search.js: ', e);
    }

    return null;
  },
  setSortBy({ commit }, { payload, query }) {
    if (query && payload !== payload.id) {
      commit('SET_SORT_BY', payload);

      try {
        query = Object.assign({}, query, { sort_by: payload.id });
        return query;
      } catch (e) {
        console.log('ERROR setSortBy in search.js: ', e);
      }
    }

    return null;
  },
  generateSearchSeed({ commit }) {
    let seed = Math.random().toString(36).slice(2);
    commit('SET_SEED', seed);
    console.log('GENERATE NEW SEARCH SEED: ', seed);
  },
  restoreInitialState({ commit }) {
    commit('SET_SELECTED_PROPERTY_TYPE', null);
    commit('SET_SELECTED_PROPERTY_TYPES', []);
    commit('SET_CURRENT_KEYWORD', null);
    commit('SET_SEARCH_TERM', null);
    commit('SET_SELECTED_REGIONS', []);
    commit('featuredAgent/RESTORE_DEFAULT_VALUE');
  },
};

// load module store di dalem folder ./search
// const requireContext = require.context('./search', false, /.*\.js$/);
// const modulesTemp = requireContext
//   .keys()
//   .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
//   .reduce((modules, [name, module]) => {
//     if (module.namespaced === undefined) {
//       module.namespaced = true;
//     }
//     return { ...modules, [name]: module };
//   }, {});
// export const modules = modulesTemp;
