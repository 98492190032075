export const state = () => ({
  loading: true,
  list: [],
});

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  RESTORE_DEFAULT_VALUE(state) {
    state.loding = false;
    state.list = [];
  },
};

export const actions = {
  async getList({ commit }, { regionIds }) {
    commit('SET_LOADING', true);

    try {
      let { data } = await this.$http.get('/api/featured_agents', {
        params: {
          region_ids: regionIds ? regionIds : [],
        },
      });
      commit('SET_LIST', data.data);
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
