<template>
  <v-app>
    <sidebar-layout></sidebar-layout>
    <header-layout :class="homepage ? 'header--homepage' : ''" v-if="!useMobileParam" />
    <div class="header--height" v-if="!useMobileParam"></div>
    <!--    <div :style="{ height: !useMobileParam ? headerHeight + 'px' : 0 }"></div>-->
    <breadcrumb :breadcrumbs="breadcrumbs" v-if="!useMobileParam" />
    <slot />
    <footer-layout v-if="useFooter && $route.query.mobile !== 'true'" />
    <modal-login></modal-login>
    <modal-register></modal-register>
    <modal-forget-pass></modal-forget-pass>
    <modal-forget-pass-confirm></modal-forget-pass-confirm>
    <modal-consultation></modal-consultation>
    <modal-phone></modal-phone>
    <modal-takeover></modal-takeover>
    <modal-contact-us />
    <modal-multiple-contact />
    <youtube-modal />
    <client-only>
      <validation-handler />
    </client-only>
  </v-app>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import Breadcrumb from '@/components/layouts/partials/breadcrumb';
const ValidationHandler = defineAsyncComponent(() =>
  import('@/components/utils/validation-handler'),
);
const HeaderLayout = defineAsyncComponent(() => import('@/components/layouts/header-layout'));
const FooterLayout = defineAsyncComponent(() => import('@/components/layouts/footer-layout'));
const SidebarLayout = defineAsyncComponent(() => import('@/components/layouts/sidebar'));
const ModalLogin = defineAsyncComponent(() => import('@/components/auth/modals/modal-login'));
const ModalRegister = defineAsyncComponent(() => import('@/components/auth/modals/modal-register'));
const ModalForgetPass = defineAsyncComponent(() =>
  import('@/components/auth/modals/modal-forget-pass'),
);
const ModalForgetPassConfirm = defineAsyncComponent(() =>
  import('@/components/auth/modals/modal-forget-pass-confirm'),
);
const ModalConsultation = defineAsyncComponent(() =>
  import('@/components/home/modals/modal-consultation'),
);
const ModalPhone = defineAsyncComponent(() => import('@/components/auth/modals/modal-phone'));
const ModalTakeover = defineAsyncComponent(() => import('@/components/home/modals/modal-takeover'));
import tawkto from '@/mixins/tawkto.js';
import { mapState } from 'vuex';
import ModalContactUs from '@/components/project-detail/modals/contact-us';
import ModalMultipleContact from '@/components/project-detail/modals/multiple-contact';
import YoutubeModal from '@/components/project-detail/youtube-modal.vue';
export default {
  head() {
    return this.head;
  },
  created() {},
  mixins: [tawkto],
  components: {
    YoutubeModal,
    Breadcrumb,
    HeaderLayout,
    FooterLayout,
    SidebarLayout,
    ValidationHandler,
    ModalLogin,
    ModalRegister,
    ModalForgetPass,
    ModalForgetPassConfirm,
    ModalConsultation,
    ModalPhone,
    ModalTakeover,
    ModalContactUs,
    ModalMultipleContact,
  },
  computed: {
    useMobileParam() {
      return this.$route.query.mobile === 'true';
    },
    headerHeight() {
      return this.$store.getters['global/getHeaderHeight']();
    },
    ...mapState({
      head: (state) => state.head.content,
    }),
  },
  props: {
    homepage: {
      type: Boolean,
      default: false,
    },
    useFooter: {
      type: Boolean,
      default: true,
    },
    breadcrumbs: { type: Array, default: () => null },
  },
};
</script>
