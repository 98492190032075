import Vue from 'vue';
import VueRouter from 'vue-router';
import MAuth from '@/middleware/auth';
import MPasswordCreated from '@/middleware/passwordCreated';
import MCreatePassword from '@/middleware/createPassword';
import MGuest from '@/middleware/guest';
import MNonAdminProjectPartner from '@/middleware/nonAdminProjectPartner';
import VueGtm from '@gtm-support/vue2-gtm';

Vue.use(VueRouter);
function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `../views/${view}.vue`);
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: loadView('index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminProjectPartner],
    },
  },
  {
    path: '/rent',
    name: 'home-rent',
    component: loadView('index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminProjectPartner],
    },
  },
  {
    path: '/buy',
    name: 'home-buy',
    component: loadView('index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminProjectPartner],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('login'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/register',
    name: 'register',
    component: loadView('register'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/forget-pass',
    name: 'forget-pass',
    component: loadView('forget-pass'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/user/activation/:token',
    name: 'user-activation',
    component: loadView('user/activation/_token'),
  },
  {
    path: '/password/reset/:token',
    name: 'reset-password',
    component: loadView('password/reset/_token'),
    meta: {
      middlewares: [MGuest],
    },
  },
  {
    path: '/user/create-password',
    name: 'create-password',
    component: loadView('user/create-password'),
    meta: {
      middlewares: [MAuth, MPasswordCreated],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: loadView('profile/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: loadView('favorites/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/jual/:category/:title/tipe/:unitTypeTitle/:hashId',
    name: 'project-detail',
    component: loadView('jual/_category/_title/tipe/_unitTypeTitle/_hashId/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminProjectPartner],
    },
  },
  {
    path: '/jual/:category/:title/:hashId',
    name: 'project-detail',
    component: loadView('jual/_category/_title/_hashId/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminProjectPartner],
    },
  },
  {
    path: '/cari',
    name: 'search',
    component: loadView('search/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminProjectPartner],
    },
  },
  {
    path: '/cari/:propertyType',
    name: 'search',
    component: loadView('search/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminProjectPartner],
    },
  },
  {
    path: '/cari/:propertyType/:query',
    name: 'search',
    component: loadView('search/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminProjectPartner],
    },
  },
  {
    path: '/user/:userName/:userHashId',
    name: 'search-user-project',
    component: loadView('search/index'),
    meta: {
      middlewares: [MNonAdminProjectPartner],
    },
  },
  {
    path: '/agen/:agentName/:agentHashId',
    name: 'search-agent-catalog',
    component: loadView('search/index'),
    meta: {
      middlewares: [MNonAdminProjectPartner],
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: loadView('change-password/index'),
    meta: {
      middlewares: [MCreatePassword, MAuth, MNonAdminProjectPartner],
    },
  },
  {
    path: '/kebijakan-privasi',
    name: 'privacy-policy',
    component: loadView('privacy-policy'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/syarat-ketentuan',
    name: 'term-of-service',
    component: loadView('term-of-service'),
    meta: {
      middlewares: [],
    },
  },
  // {
  //   path: '/help',
  //   name: 'help-index',
  //   component: loadView('help/_type/index'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  // {
  //   path: '/help/:type',
  //   name: 'help',
  //   component: loadView('help/_type/index'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  {
    path: '/direktori/:propertyType',
    name: 'directory',
    component: loadView('directory/index'),
    meta: {
      middlewares: [],
    },
  },
  // {
  //   path: '/mitra-agen',
  //   name: 'agent-partner',
  //   component: loadView('agent-partner'),
  //   meta: {
  //     middlewares: [],
  //   },
  // },
  {
    path: '/mitra-proyek',
    name: 'developer-partner',
    component: loadView('developer-partner'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/bantu-cari-properti',
    name: 'help-find-residence',
    component: loadView('help-find-residence'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/banner',
    name: 'banner',
    component: loadView('banner/index'),
    meta: {
      middlewares: [MCreatePassword, MNonAdminProjectPartner],
    },
  },
  {
    path: '/banner/detail/:uuid',
    name: 'banner-detail',
    component: loadView('banner/detail/_uuid/index'),
    meta: {
      middlewares: [MCreatePassword],
    },
  },
  {
    path: '/kpr',
    name: 'mortgage',
    component: loadView('mortgage/index'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/kalkulator',
    name: 'mortgage-calculator',
    component: loadView('mortgage/calculator'),
    meta: {
      middlewares: [],
    },
  },
  {
    path: '/lms',
    name: 'packages',
    component: () => import('@/views/packages/index.vue'),
    meta: {
      title: 'Listing Management Service Package',
    },
  },
  { path: '*', component: loadView('404') },
];

export default () => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        console.log('ADA HASH-NYA BUAT ANCHOR: ', to.hash);
        return {
          selector: to.hash,
        };
      } else {
        return { x: 0, y: 0 };
      }
    },
  });

  if (process.env.NODE_ENV === 'production') {
    Vue.use(VueGtm, {
      id: 'GTM-NJ5PR7T',
      vueRouter: router,
      debug: false,
    });
  }

  return router;
};
